(function($) {
    'use strict'; // Start of use strict
    var win = $(window);
    var mainNavHeight = $('.main-nav').outerHeight();

    // jQuery for page scrolling feature - requires jQuery Easing plugin
    $(document).on('click', 'a.page-scroll', function(event) {
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: ($($anchor.attr('href')).offset().top - $('.navbar').outerHeight())
        }, 1250, 'easeInOutExpo');
        event.preventDefault();
    });

    win.scroll(function(){
      if (win.scrollTop() > (mainNavHeight / 2)){
        $('.main-nav').addClass('scrolled');
      } else {
        $('.main-nav').removeClass('scrolled');
      }
    });

  if($('.masonry').length != 0) {
    // init Masonry
    var $masonry = $('.masonry').masonry({
      // options
      itemSelector: '.masonry-item',
      columnWidth: '.masonry-sizer',
      percentPosition: true
    });
    // layout Masonry after each image loads
    $masonry.imagesLoaded().progress( function() {
      $masonry.masonry('layout');
    });

    $('.masonry').on('click', '[data-toggle="lightbox"]', function(event) {
        event.preventDefault();
        $(this).ekkoLightbox();
    });
  }

  // LIGHTBOX FOR SINGLE IMAGES
  $('.use-lightbox').on('click', '[data-toggle="lightbox"]', function(event) {
      event.preventDefault();
      $(this).ekkoLightbox();
  });



  // CAROUSELS
  if($('.carousel-gallery-items').length != 0) {
    $('.carousel-gallery-items').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dots: true,
      dotsClass: 'carousel-nav-pills',
      centerMode: true,
      centerPadding: 0,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            arrows: true,
            dots: false,
            centerMode: true,
            slidesToShow: 1,
            variableWidth: false

          }
        }
      ]
    });
  }

  $('.send-email-button').on('click', this, function(e) {
    e.preventDefault();

    var frm = $(this).parents('.send-email-form');
    $.ajax({
      type: frm.attr('method'),
      url: frm.attr('action'),
      data: frm.serialize(),
      dataType: 'json',
      success: function(response) {
        // console.log(response);
        $(frm).find('.send-email-result').html(response.message);
      }
    });

  });


  /**
  * COLLAPSIBLE PANEL
  */
  // ON LOAD
  var collapsePanelControl = $('.collapsible-panel-control');
  var collapsePanelSection = collapsePanelControl.parents('.page-section');
  var collapsePanelHeightWithPadding = collapsePanelSection.outerHeight();

  // CHECK COOKIE
  var cookieValue = readCookie('collapseStartState');
  if (typeof cookieValue === 'undefined' || cookieValue === null) {
    var collapseStartState = collapsePanelControl.attr('data-startstate');
  } else {
    var collapseStartState = cookieValue;
  }
  collapsePanelSection.addClass(collapseStartState);



  collapsePanelControl.on('click',function(e) {
    e.preventDefault();
    var section = $(this).parents('.page-section');
    if(section.hasClass('open-panel')) {
      bakeCookie ('collapseStartState','close-panel',0);
    } else if (section.hasClass('close-panel')) {
      bakeCookie ('collapseStartState','open-panel',0);
    }
    section.toggleClass('open-panel');
    section.toggleClass('close-panel');

  });




  /* SEND LINK EVENTS TO GOOGLE ANALYTICS */
  var filetypes = /\.(pdf|doc.*|xls.*|ppt.*|txt|mp3|mp4|m4a|wma|mov|avi|wmv|flv|wav|zip|rar|exe|dmg)$/i;
  var baseHref = '';
  if ($('base').attr('href') != undefined) {
    baseHref = $('base').attr('href');
  }

  $('a').on('click', function(event) {
    var pathArray = window.location.pathname.split( '/' );
    var category = pathArray[1];

    var el = $(this);
    var track = true;
    var href = (typeof(el.attr('href')) != 'undefined' ) ? el.attr('href') :'';

    // var isThisDomain = href.match(document.domain.split('.').reverse()[1] + '.' + document.domain.split('.').reverse()[0]);
    // console.log(isThisDomain);
    // console.log(href.match(/^https?\:/i));

    var isThisDomain = true;
    if (this.host !== window.location.host) {
      var isThisDomain = false;
    }

    if (!href.match(/^javascript:/i)) {
      var elEv = []; elEv.value=0, elEv.non_i=false;
      if (href.match(/^mailto\:/i)) {
        elEv.category = category;
        elEv.action = 'Email';
        // elEv.label = href.replace(/^tel\:/i, ''); // eamil address
        elEv.label = window.location.pathname; // page path
        elEv.loc = href;

      } else if (href.match(filetypes)) {
        var extension = (/[.]/.exec(href)) ? /[^.]+$/.exec(href) : undefined;
        elEv.category = category;
        elEv.action = 'Download ' + extension[0];
        elEv.label = href.replace(/ /g,'-');
        elEv.loc = baseHref + href;

      } else if (href.match(/^https?\:/i) && !isThisDomain) {
        elEv.category = category;
        elEv.action = 'Outbound Link';
        elEv.label = href.replace(/^https?\:\/\//i, '');
        elEv.loc = href;

      } else if (href.match(/^tel\:/i)) {
        elEv.category = category;
        elEv.action = 'Click to Call';
        // elEv.label = href.replace(/^tel\:/i, ''); // phone number
        elEv.label = window.location.pathname; // page path
        elEv.loc = href;

      } else {
        track = false;
      }
    } else {
      // href="javascript:"
      track = false;
    }
    // console.log(track);
    console.log(elEv);
    if (track) {
      // USING NEW gjtag.js
      gtag('event', elEv.action, {
        'event_category': elEv.category,
        'event_label': elEv.label,
        'value': elEv.value,
        'non_interaction': elEv.non_i
      });

      if ( el.attr('target') == undefined || el.attr('target').toLowerCase() != '_blank') {
        setTimeout(function() { location.href = elEv.loc; }, 400);
        return false;
      }
    }
  });

  $('.nav-logo').hover(
    function() {
      $('.nav-logo, .nav-logo-hover').toggleClass('hide');
    },
    function() {
      $('.nav-logo, .nav-logo-hover').toggleClass('hide');
    }
  );


})(jQuery); // End of use strict

function bakeCookie(name,value,days) {
	if (days) {
		var date = new Date();
		date.setTime(date.getTime()+(days*24*60*60*1000));
		var expires = '; expires='+date.toUTCString();
	}	else {
    var expires = '';
  }
	document.cookie = name+'='+value+expires+'; path=/';
  // console.log('Mmmm.... smells scrumptious!');
}

function readCookie(name) {
	var nameEQ = name + '=';
	var ca = document.cookie.split(';');
	for (var i=0;i < ca.length;i++) {
		var c = ca[i];
		while (c.charAt(0)==' ') {
      c = c.substring(1,c.length);
    }
		if (c.indexOf(nameEQ) == 0) {
      return c.substring(nameEQ.length,c.length);
    }
	}
  // console.log('Look ma... A Cookie!');
	return null;
}

function eatCookie(name) {
  // console.log('Yummm...That was delicious!');
	bakeCookie(name,'',-1);
}
